import { getApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBkBQiN5yxZXqYfXUAp44AVQcnC8FxO1kA",
  authDomain: "tracecap-35443.firebaseapp.com",
  projectId: "tracecap-35443",
  storageBucket: "tracecap-35443.appspot.com",
  messagingSenderId: "943296273984",
  appId: "1:943296273984:web:d6af1044990dcdecc889a5",
};

export const firebaseApp = initializeApp(firebaseConfig);

const functions = getFunctions(getApp());

if (import.meta.env.DEV) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();

export const db = getFirestore(firebaseApp);

export const projecstRef = collection(db, "projects");

export const getUserClient = async () => {
  if (!auth.currentUser) return null;

  const {
    claims: { admin, clientId },
  } = await auth.currentUser.getIdTokenResult();

  if (admin || typeof clientId !== "string") return null;

  return clientId;
};

export interface Project {
  name: string;
  email: string;
}

export interface ClientData {
  name: string;
}

interface Doc {
  id: string;
}

export interface Client extends ClientData, Doc {}

export const addProject = async ({
  project: { name, email },
  clientId,
}: {
  project: Project;
  clientId: string;
}) => {
  const projectDocRef = await addDoc(collection(db, "projects"), {
    name,
    clientId,
  });
  const constactsRef = collection(projectDocRef, "contacts");
  await addDoc(constactsRef, { email });
  return projectDocRef;
};

export const getProject = async (projectId: string) => {
  const docRef = doc(db, "projects", projectId);
  const docSnap = await getDoc(docRef);

  return docSnap.data();
};

export const getItem = async (uuid: string) => {
  const ref = collection(db, "bridgeItems");

  const q = query(ref, where("user_uuid", "==", uuid));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs[0];
};

export const getProjectAccounts = async (projectId: string) => {
  const docRef = doc(db, "projects", projectId);

  return getDocs(collection(docRef, "bridgeAccounts"));
};

export const setAccountId = async ({
  projectId,
  accountId,
}: {
  projectId: string;
  accountId: string;
}) => {
  const docRef = doc(db, "projects", projectId);
  updateDoc(docRef, {
    accountId,
  });
};

/** Permet à l'admin de créer un client */
export const createClient = async ({ email, name }: { email: string; name: string }) => {
  const batch = writeBatch(db);

  const clientRef = doc(collection(db, "clients"));
  const inviteRef = doc(collection(db, "clientInvites"));

  batch.set(clientRef, { name });
  batch.set(inviteRef, { email, clientId: clientRef.id });

  return batch.commit();
};

const callGetLink = httpsCallable<{ projectId: string }, string>(functions, "getBridgeLink");

const callGetSyncLink = httpsCallable<{ projectId: string }, string>(
  functions,
  "getBridgeSyncLink",
);

export const getBridgeConnectLink = (p: { projectId: string }) =>
  callGetLink(p).then((result) => result.data);

export const getBridgeSyncLink = (p: { projectId: string }) =>
  callGetSyncLink(p).then((result) => result.data);

const callResendAuthLink = httpsCallable<
  { uuid?: string; projectId?: string; contactId: string },
  string
>(functions, "resendAuthLink");

export const resendAuthLink = (p: { uuid?: string; projectId?: string; contactId: string }) =>
  callResendAuthLink(p).then((result) => result.data);

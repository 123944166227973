/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#00A9BA",
          success: "#00A9BA",
          error: "#D75A00",
        },
      },
    },
  },
  defaults: {
    VBtn: {
      variant: "flat",
    },
    VAutocomplete: {
      variant: "outlined",
      density: "compact",
    },
    VSelect: {
      variant: "outlined",
      density: "compact",
    },
    VTextField: {
      variant: "outlined",
      density: "compact",
    },
  },
});

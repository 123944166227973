<template>
  <v-layout full-height style="height: 100vh">
    <router-view />
    <SnackbarController></SnackbarController>
  </v-layout>
</template>

<script lang="ts" setup>
import { watch } from "vue";
import { useRouter } from "vue-router";
import { useCurrentUser } from "vuefire";
import SnackbarController from "@/components/SnackbarController.vue";
import { useUserStore } from "@/store";

const user = useCurrentUser();
const router = useRouter();
const { getClientId, setClaims } = useUserStore();

watch(user, async (currentUser, previousUser) => {
  getClientId();

  if (currentUser) {
    const { claims } = await currentUser.getIdTokenResult();
    setClaims(claims);
  } else {
    setClaims(null);
  }

  // It's a logout, redirect to login
  if (!currentUser && previousUser) {
    return router.push({ name: "Login" });
  }
});
</script>
